import React, {useState} from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import NewsSection from '../components/news-section'
import Row from '../components/row'
import styles from '../components/application.module.css'
import SectionHeader from '../components/section-header'

function AboutPage(props) {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const images = props.data.allContentfulAsset.nodes
    const img5 = images.find(img => img.file.fileName.indexOf('oj') !== -1)
    const img4 = images.find(img => img.file.fileName.indexOf('matt') !== -1)
    const img3 = images.find(img => img.file.fileName.indexOf('ben') !== -1)
    const img2 = images.find(img => img.file.fileName.indexOf('group') !== -1)
    const img1 = images.find(img => img.file.fileName.indexOf('banner') !== -1)

    const sectionData = {
      title: 'News and Updates'
    }


    const data5 = {
      date: 'Apr 2023',
      title: 'BMRF Spring \'23 Update',
      img: img5.file.url,
      link: 'https://us5.campaign-archive.com/?u=9bba121c8b34837921ae869cf&id=1638d290ae'
    }

    const data4 = {
      date: 'July 2022',
      title: 'Meet Our Scholarship Recipients',
      img: img4.file.url,
      link: 'https://us5.campaign-archive.com/?u=9bba121c8b34837921ae869cf&id=d8eee09941'
    }

    const data3 = {
      date: 'March 2022',
      title: 'First Scholarships Awarded',
      img: img3.file.url,
      link: 'https://us5.campaign-archive.com/?u=9bba121c8b34837921ae869cf&id=b0ec7f3225'
    }

    const data2 = {
      date: 'November 2021',
      title: 'Outdoor Jam Recap',
      img: img2.file.url,
      link: 'https://mailchi.mp/dc1e23b77575/ben-meyer-recovery-foundations-2021-outdoor-jam-recap'
    }

    const data1 = {
      date: 'September 2021',
      title: 'Updates on the Outdoor Jam',
      img: img1.file.url,
      link: 'https://us5.campaign-archive.com/?u=9bba121c8b34837921ae869cf&id=479b80a032'
    }

    return (
      <Layout location={props.location}>
          <Helmet title={siteTitle} />
          <SectionHeader data={sectionData} />
          <div style={{minHeight: '50vh'}}>
            <Row>
              <NewsSection data={data5} />
            </Row>
            <hr />
            <Row>
              <NewsSection data={data4} />
            </Row>
            <hr />
            <Row>
              <NewsSection data={data3} />
            </Row>
            <hr />
          <Row>
            <NewsSection data={data2} />
          </Row>
          <hr />
          <Row>
            <NewsSection data={data1} />
          </Row>
          </div>

      </Layout>
    )
}

export default AboutPage

export const pageQuery = graphql`
query NewsQuery {
allContentfulAsset(filter: {title: {regex: "/newsletter/"}}) {
  nodes {
    file {
      fileName
      url
    }
  }
}
}

`
