import './base.css'
import React from 'react';

export default function NewsSection(props) {
  const date = props.data.date;
  const link = props.data.link;
  const title = props.data.title;
  const img = props.data.img;
  return (
    <div style={{margin: '3% 0', textAlign: 'center'}}>
      <h1>{date}</h1>
      <img src={img} style={{maxHeight: '180px', margin: '15px 0'}}/>
      <a href={link} target='_blank'><h3>{title} &#8594;</h3></a>
    </div>
  )
}
